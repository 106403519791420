var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-shopping")]),_vm._v(" Produtos ")],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"10"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.product},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                                                name: 'product',
                                                params: { id: item.id }
                                            }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }